module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://use.typekit.net","https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Adobe Typekit Fonts","file":"https://use.typekit.net/pua4ttg.css"},{"name":"Google Fonts","file":"https://fonts.googleapis.com/css2?family=Archivo:wght@100;300;400;500;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0cdb9a9d3d1731a54a0c10ac0fb40b9b"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
